@font-face {
  font-family: ka1;
  src: url('../../public/karmatic_arcade/ka1.ttf');
}

@font-face {
  font-family: editundo;
  src: url('../../public/edit_undo_font/editundo.ttf');
}

#contact {
    /*background-image: url(https://storage.googleapis.com/z_static/Buildings_PinkBlue.png);*/
    clip-path: polygon(0 0, 20% 100px, 100% 0, 100% 100%, 0 100%);
    color: #0b0b0b;
    min-height: 100vh;
    width: 100%;
    padding: 5rem 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: -100px 0 140px;
    z-index: 1;
  }
  
  #contact .container {
    width: 70%;
    max-width: 1200px;
    padding: 25vh 0;
  }
  
  #contact .container .heading-wrapper {
    display: flex;
    justify-content: space-between;
  }
  
  #contact .heading-wrapper .social a {
    color: #fafafa;
  }
  
  #contact .heading-wrapper .social a:hover {
    color: rgb(218, 8, 153);
  }
  
  .heading-wrapper .heading .title {
    font-family: 'ka1';
    color: white;
    font-size: 3rem;
    line-height: 3.4rem;
  }
  
  .heading-wrapper .heading .separator {
    background: rgb(218, 8, 153);
    width: 150px;
    height: 2px;
    margin: 1rem 0;
  }
  
  .heading-wrapper .heading .subtitle {
    font-size: 1.4rem;
    font-family: 'editundo';
    color: gainsboro;
  }
  
  #contact-form {
    margin-top: 1rem;
  }
  
  input, textarea {
    border: none;
    padding: 1rem;
    font-family: 'editundo', Arial, Helvetica, sans-serif;
    width: 100%;
    height: 40%;
    transition: 200ms;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="text"]:not(output):not(:focus),
  input[type="email"]:not(output):not(:focus),
  textarea {
    border-bottom: 1px solid #fafafa;
    background: transparent;
    color: #fafafa;
    font-family: 'editundo';
    font-size: 1.8rem;
    box-shadow: none;
    outline: none;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  input[type="text"]:not(output):focus,
  input[type="email"]:not(output):focus,
  textarea:focus {
    border-bottom: 1px solid rgb(218, 8, 153);
    font-family: 'editundo';
  }
  
  input[type="submit"] {
    font-family: 'editundo';
    background: rgb(218, 8, 153);
    color: #ffffff;
    margin-top: 1rem;
    width: auto;
    float: right;
  }
  
  input[type="submit"]:hover,
  input[type="submit"]:focus {
    cursor: pointer;
    color: #333;
    background: #fafafa;
  }
  
  ::placeholder {
    color: rgb(116, 202, 251);
    font-family: 'editundo';
  }
  
  /** Email to avoid spam **/
  .mail {
    display: inline-block;
    font-style: italic;
  }
  
  .mail .at, .mail .dot {
    font-size: .9rem;
    margin: 0 .1rem;
    color: rgb(218, 8, 153);
  }
  
  
  @media only screen and (max-width: 1149px) {
    #contact .social a {
      display: block;
    }
  }
  
  @media only screen and (max-width: 649px) {
    #contact {
      clip-path: polygon(0 0, 20% 5%, 100% 0, 100% 100%, 0 100%);
      padding: 0;
    }
  }
  