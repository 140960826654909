@font-face {
    font-family: ka1;
    src: url('../../public/karmatic_arcade/ka1.ttf');
  }
  
  @font-face {
    font-family: editundo;
    src: url('../../public/edit_undo_font/editundo.ttf');
  }

  .cardStyle {
    background-color: rgba(0, 0, 0, 0.998) !important;
  }