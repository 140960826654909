.Downarrow {
    max-width: 10%;
    max-height: 10%;
}

/*.Left::before {
    border-width: 10px !important;
    border-color: orange !important;
}*/

/*
linear-gradient(90deg, rgb(20, 54, 100), rgb(0,0,0), rgb(250,250,25))
background: linear-gradient(90deg, hsl(196, 100%, 55%), hsl(196, 100%, 32%), hsl(196, 100%, 55%)) !important;
border-image: linear-gradient(blue, red) !important;
*/