.MuiList-root {
    background-color: #ff00c8 !important;
    color: #fff !important;
    border-radius: 8px !important;
    border-color: rgb(84, 197, 247);
    padding: 8px !important;
}

.MuiPaper-root {
    background-color: rgb(0, 0, 0) !important;
}

@font-face {
    font-family: ka1;
    src: url('../../public/karmatic_arcade/ka1.ttf');
  }
  
  @font-face {
    font-family: editundo;
    src: url('../../public/edit_undo_font/editundo.ttf');
  }