@font-face {
    font-family: ka1;
    src: url('../../public/karmatic_arcade/ka1.ttf');
  }
  
  @font-face {
    font-family: editundo;
    src: url('../../public/edit_undo_font/editundo.ttf');
  }

  .custom-typography {
    font-family: 'ka1', sans-serif;
  }

  .custom-avatar {
    font-family: 'ka1', sans-serif;
  }

  .MuiPaper-root {
    background-color: #ff00c8 !important;
    border-color: rgb(84, 197, 247) !important;
    padding: 8px !important;
}