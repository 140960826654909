/*****************
 *****************
   ABOUT STYLES
 *****************
 *****************/
 @font-face {
  font-family: ka1;
  src: url('../../public/karmatic_arcade/ka1.ttf');
}

@font-face {
  font-family: editundo;
  src: url('../../public/edit_undo_font/editundo.ttf');
}

 #about {
    height: 100%;
    min-height: 100vh;
    font-size: 1.4rem;
    position: relative;

    z-index: 5;
    background: rgb(255, 255, 255) center right no-repeat;
    background-attachment: fixed;
  }
  
  #about .wrapper {
    padding: 5rem 5rem 3rem;
    height: 100%;
    min-height: 100vh;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  #about article {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    padding: 1rem 0;
  }
  
  #about .title {
    grid-column-end: span 4;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #about .title h3 {
    font-size: 2.4rem;
  }
  
  #about .separator {
    background: #000000;
    width: 150px;
    height: 2px;
    margin: 1rem 0;
    padding: 0;
  }
  
#about .blank-separator {
  background: black;
  width: 150px;
  height: 2px;
  margin: 1rem 0;
  padding: 0;
}

/*Styling for text description under titles or headings.*/
  #about .subtitle {
    /*font-family: 'ka1';*/
    font-size: 1.6rem;
    text-align: center;
    color: inherit;
    padding-bottom: 1.5rem;
  }
  
  #about p {
    padding-bottom: 1.5rem;
    color: #ffffff;
    line-height: 1.9rem;
  }
  
  #about .desc.full {
    grid-column-end: span 4;
    margin-bottom: 1.5rem;
    align-items: center;
    text-align: center;
  }
  
  #about .desc {
    grid-column-end: span 1;
    background: #ffffffee; /*Color around text boxes*/
    padding: .1rem;
    text-align: justify;
  }
  
  @media only screen and (max-width: 2000px) {
    #about article {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    #about .desc.full {
      grid-column-end: -1;
    }
  
    #about .desc {
      grid-column-end: -1;
    }
  }

  @media only screen and (max-width: 2000px) {
    #about {
      clip-path: polygon(0 0, 20% 2%, 100% 0, 100% 100%, 80% 98%, 0 100%);
      background-position: top left;
      background-size: cover;
    }
  }
  @media only screen and (max-width: 2000px) {
    #about .wrapper {
      padding: 4rem 2rem 8rem;
    }
  }
  
.clipper {
  clip-path: polygon(0 0, 20% 5%, 100% 0, 100% 100%, 80% 95%, 0 100%);
  /*background-image: url('https://storage.googleapis.com/z_static/Trim.png')*/
}

.textStyle {
  font-family: 'ka1';
  background-color: rgba(0,0,0,0);

}
  /*clip-path: polygon(0 0, 20% 5%, 100% 0, 100% 100%, 80% 95%, 0 100%);*/