@font-face {
    font-family: ka1;
    src: url('../../public/karmatic_arcade/ka1.ttf');
  }
  
  @font-face {
    font-family: editundo;
    src: url('../../public/edit_undo_font/editundo.ttf');
  }

  .stickyDiv {
    font-family: 'ka1';
    background-color: rgba(0,0,0,0);
  }

  #headz {
    border-color: #61CFFB !important;
    vertical-align: bottom;
  }

  #barContainer {
    height: 100%;
    position: relative
  }