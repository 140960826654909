@font-face {
    font-family: ka1;
    src: url('../../public/karmatic_arcade/ka1.ttf');
  }
  
  @font-face {
    font-family: editundo;
    src: url('../../public/edit_undo_font/editundo.ttf');
  }

  body {
    background-color: #000; /* Optional for full arcade effect */
    font-family: "Press Start 2P", sans-serif;
  }

/* Glow animation */
@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(255, 0, 153, 0.5), 0 0 20px rgba(255, 0, 153, 0.4);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 0, 153, 0.8), 0 0 30px rgba(255, 0, 153, 0.6);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 0, 153, 0.5), 0 0 20px rgba(255, 0, 153, 0.4);
  }
}

@keyframes glow-border {
  0% { box-shadow: 0 0 5px #ff0099, 0 0 10px #ff6600; }
  50% { box-shadow: 0 0 10px #ff0099, 0 0 15px #ff6600; }
  100% { box-shadow: 0 0 5px #ff0099, 0 0 10px #ff6600; }

  50% { box-shadow: 0 0 20px #ff0099, 0 0 30px #ff6600; }
  100% { box-shadow: 0 0 10px #ff0099, 0 0 20px #ff6600; }
}

@keyframes text-glow {
  0% {
    text-shadow: 0 0 5px #ff0099, 0 0 10px #ff6600;
  }
  50% {
    text-shadow: 0 0 15px #ff0099, 0 0 20px #ff6600;
  }
  100% {
    text-shadow: 0 0 5px #ff0099, 0 0 10px #ff6600;
  }
}


.retro-console-background {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 150px; /* Default height for mobile view */
  overflow: hidden;

  /* Background and glow effects */
  background-image: radial-gradient(ellipse at center, rgba(255, 0, 153, 0.5) 0%, rgba(0, 0, 0, 0.9) 100%), url('../Media/ConsoleBackground.png');
  background-size: cover;
  background-position: center 60%;
  background-repeat: no-repeat;
  border: 10px solid;
  border-color: rgb(0, 0, 0);
  box-shadow: 0 0 15px rgba(255, 0, 153, 0.9);
  animation: glow-border 2s infinite;
  padding-left: 5px;
  margin-bottom: 25px;

  @media (min-width: 768px) {
      height: 150px; /* Controlled height for desktop view */
      margin: 0 auto;
      border-radius: 15px;
      padding-left: 20px;
      margin-top: 5px;
      margin-bottom: 25px;
      animation: glow-border 2s infinite;
  }

}


/* Hover effects for thumbnails */
.retro-thumbnail:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease-in-out;
}

.text-glow {
  animation: text-glow 2s infinite;
}